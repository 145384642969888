<template>
  <div>
    <b-form @submit.prevent>
      <b-row>
        <b-col md="12">
          <b-form-group
            label="Nombre del exámen*"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="formData.name"
              placeholder="Nombre"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Descripción"
            label-for="description"
          >
            <b-form-textarea
              id="description"
              v-model="formData.description"
              placeholder="Descripción"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Dependencia*"
            label-for="status"
          >
            <b-form-select
              v-model="formData.dependency_id"
              :options="dependencies"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Estado*"
            label-for="status"
          >
            <b-form-select
              v-model="formData.is_active"
              :options="statuses"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" />
        <b-col
          md="6"
          class="text-right"
        >
          <b-button
            type="button"
            variant="primary"
            :disabled="isBusy || $v.$invalid"
            @click="saveData"
          >
            <b-spinner
              v-if="isBusy"
              small
            />
            <v-icon
              v-else
              name="save"
            />
            Guardar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
  import {
    BForm,
    BFormGroup,
    BButton,
    BCol,
    BFormInput,
    BRow,
    BSpinner,
    BFormSelect,
    BFormTextarea,
  } from 'bootstrap-vue'
  import { required } from 'vuelidate/lib/validators'
  import CRUDMixin from '@/mixins/CRUDMixin'

  export default {
    name: 'AddEditExamForm',
    components: {
      BForm,
      BFormGroup,
      BButton,
      BCol,
      BFormInput,
      BRow,
      BSpinner,
      BFormSelect,
      BFormTextarea,
    },
    mixins: [CRUDMixin],
    props: {
      record: {
        type: Object,
        default: null,
      },
    },
    inject: ['examsRepository', 'dependenciesRepository'],

    data() {
      return {
        formData: {
          name: '',
          description: '',
          is_active: true,
          dependency_id: '',
        },
        repository: 'examsRepository',
        isBusy: false,
        statuses: [
          {
            value: true,
            text: 'Activo',
          },
          {
            value: false,
            text: 'Inactivo',
          },
        ],
        dependencies: [],
      }
    },

    async mounted() {
      const me = this
      if (me.record) {
        me.formData = { ...me.record }
      }
      const result = await me.dependenciesRepository.getAll()
      me.dependencies = result.data.map(item => ({
        value: item.id,
        text: item.name,
      }))
    },

    validations: {
      formData: {
        name: {
          required,
        },
        is_active: {
          required,
        },
        dependency_id: {
          required,
        },
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
